import React from 'react'
import '../../App.css';

function Products() {
  return (
<div> <h1 className='products'> </h1></div>
  
  
  );
}

export default Products;
